<template>
    <div class="topbar">
        <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container">
                <a class="navbar-brand" href="#">YCPS</a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item">
                            <a class="nav-link" href="#">首页</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#aboutus">关于我们</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#joinus">加入我们</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<style>
.navbar {
    background-color: #ffffff;
    border-bottom: 1px solid #e1e1e1;
}
.navbar-brand {
    font-weight: bold;
}
.hero {
    background-color: #f9f9f9;
    padding: 80px 0;
    text-align: center;
    border-bottom: 1px solid #e1e1e1;
}
.hero h1 {
    font-size: 3rem;
    font-weight: 600;
}
.btn-custom {
    background-color: #0078d4;
    color: white;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 1.25rem;
}
.btn-custom:hover {
    background-color: #005a9e;
}
.section {
    padding: 60px 0;
}
.section-title {
    font-size: 2rem;
    margin-bottom: 30px;
    font-weight: 600;
    text-align: center;
}    
.card {
    border: none;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.card img {
    width: 100%;
    height: auto;
}
.footer {
    background-color: #ffffff;
    padding: 40px 0;
    text-align: center;
    font-size: 0.875rem;
    color: #6e6e73;
    border-top: 1px solid #e1e1e1;
}
.placeholder {
    background-color: #e0e0e0;
    height: 200px;
    width: 100%;
    margin-bottom: 15px;
}
</style>

<script>
export default {
  name: 'TopBar'
}
</script>