<template>
    <footer class="footer">
        <div class="container">
            <p>© 2024 青少年计算机促进学会（YCPS） | 保留所有权利</p>
        </div>
    </footer>
</template>

<style>
.footer {
    background-color: #ffffff;
    padding: 40px 0;
    text-align: center;
    font-size: 0.875rem;
    color: #6e6e73;
    border-top: 1px solid #e1e1e1;
}
</style>

<script>
export default {
  name: 'FootBar'
}
</script>