<template>
    <div class="section" id="aboutus">
        <div class="container">
            <h2 class="section-title">
                关于我们
            </h2>
            <div class="row">
                <div class="col-md-6 mb-6">
                    <div class="cardp">
                        <div class="card-body text-center">
                            <h3>会长有话说</h3>
                            <p class="sp">青少年计算机促进学会（筹备中），简称青计会（Youth Computer Promotion Society），是一个由全国各地青少年自发组织的公益性组织。我们的使命是推动计算机科学在社会，尤其是青少年群体中的普及与发展。我们通过科普教育、教育合作、项目研发等多种方式，致力于实现这一目标。我们的目标是汇聚全国青少年计算机爱好者的智慧与热情，共同推进计算机技术的普及，并展示中国青少年在计算机领域的卓越才能。</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-6">
                    <div class="cardpp">
                        <div class="card-body text-center">
                            <h3>关于未来…</h3>
                            <p class="sp"><img src="../assets/future_say.png" width="100%"></p>
                            <p>鉴于内容比较少，贴图片能够让它看起来更多……<br><br><br> 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section" id="joinus">
        <div class="container">
            <h2 class="section-title">
                加入我们
            </h2>
            <div class="row">
                <div class="col-md-4 mb-4">
                    <div class="cardp">
                        <div class="card-body text-center">
                            <h3>邮箱沟通</h3>
                            <p>我们有独立的域名邮箱，由副会长Alex提供相关的技术支持。</p>
                            <p>
                                负责人个人邮箱：<br><b><a href="mailto:admin@alexweb.space">admin@alexweb.space</a></b><br>
                                工作邮箱：<br><b><a href="mailto:alex@ycps.org.cn">alex@ycps.org.cn</a></b>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="cardp">
                        <div class="card-body text-center">
                            <h3>QQ沟通</h3>
                            <p>我们起源于中国内地，成员来自于五湖四海。显然，最适合我们的就是QQ。</p>
                            <p>
                                总会大群：<br><b><a href="#">已加密，请私信获取</a></b><br>
                                会长（审核）QQ：<br><b><a href="#">2872386143 CleanIce</a></b>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="cardp">
                        <div class="card-body text-center">
                            <h3>更多联系方式</h3>
                            <p>为满足国内外成员的需求，我们开设了以下群组或账号——</p>
                            <p>
                                账号：<br><b><a href="https://github.com/YCPS-org">github</a></b><br>
                                群组：<br><b><a href="https://t.me/+8FSS5Ammcw84ODE1">telegram</a></b>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section" id="aboutus">
        <div class="container">
            <h2 class="section-title">
                更多信息
            </h2>
            <div class="row">
                <div class="col-md-6 mb-6">
                    <div class="cardp">
                        <div class="card-body text-center">
                            <h3>成员与组织架构</h3>
                            <p class="spc">&emsp;&emsp;青计会的管理层设有会长、副会长等一众不同职务。同时，我们的成员也大多一直在群内活跃——以至于和管理层几乎差别不大，共同参与青计会的管理任务。</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-6">
                    <div class="cardpp2">
                        <div class="card-body text-center">
                            <h3>捐助与帮助</h3>
                            <p class="spc">&emsp;&emsp;在青记会发展前期，我们期待、也希望能够收到来自不同渠道的帮助。形式包括但不限于人员支持、资金支持等；若您有能力提供一定程度上的资助，我们将会授予您特别的荣誉。<br><br><br> 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>   
.card {
    border: none;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.card img {
    width: 100%;
    height: auto;
}
.cardp {
    border: none;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 15%;
    padding-bottom: 10%;
}
.cardpp {
    border: none;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 10%;
}
.cardpp2 {
    border: none;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding-top: 15%;
    padding-left: 10%; padding-right: 10%;
    padding-bottom: 2.5%;
}
.placeholder {
    background-color: #e0e0e0;
    height: 200px;
    width: 100%;
    margin-bottom: 15px;
}
.section {
    padding: 60px 0;
}
.section-title {
    font-size: 2rem;
    margin-bottom: 30px;
    font-weight: 600;
    text-align: center;
}
.sp {
    margin-top: 20px;
}
.spc {
    margin-top: 20px;
    text-align: left;
}
</style>

<script>
export default {
  name: 'SpecialPart'
}
</script>