<template>
  <TopBar/>
  <MainPart/>
  <SpecialPart/>
  <FootBar/>
</template>

<script>
import TopBar from './components/TopBar.vue';
import MainPart from './components/MainPart.vue';
import SpecialPart from './components/SpecialPart.vue';
import FootBar from './components/FootBar.vue';

export default {
  name: 'App',
  components: {
    TopBar,
    MainPart,
    SpecialPart,
    FootBar
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0;
}
</style>
