<template>
    <div class="hero">
        <div class="container">
            <h1>YCPS - 青少年计算机促进学会</h1>
            <p>下一代计算机科学家和工程师培养基地</p>
            <a href="#joinus" class="btn btn-custom">加入我们</a>
        </div>
    </div>
</template>

<style>
.hero {
    background-color: #f9f9f9;
    padding: 80px 0;
    text-align: center;
    border-bottom: 1px solid #e1e1e1;
}
.hero h1 {
    font-size: 3rem;
    font-weight: 600;
}
.hero p {
    margin-top: 40px;
}
.btn-custom {
    margin-top: 40px;
    background-color: #61bbff;
    color: white;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 1.25rem;
}
.btn-custom:hover {
    background-color: #7bc6ff;
    color:rgb(240, 253, 255);
}
</style>

<script>
export default {
  name: 'MainPart'
}
</script>